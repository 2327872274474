import { inject } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { filter, map, pairwise } from 'rxjs';
import { NavigationActions } from './custom-router.actions';
import { ViewportScroller } from '@angular/common';

export const saveNavigationEnd = createEffect(
  (router = inject(Router)) => {
    return router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(({ urlAfterRedirects, url }) =>
        NavigationActions.navigationEnd({ url: urlAfterRedirects || url })
      )
    );
  },
  { functional: true }
);

export const saveScrollPosition = createEffect(
  (router = inject(Router), viewportScroller = inject(ViewportScroller)) => {
    return router.events.pipe(
      filter((event): event is Scroll => event instanceof Scroll),
      pairwise(),
      map((e: Scroll[]) =>{
        const previous = e[0];
        const current = e[1];
        const state = router.lastSuccessfulNavigation?.extras.state
        const noScroll = state?.['noScroll']
        const position = state?.['position']

        if (noScroll && previous.routerEvent?.['urlAfterRedirects'] !== current.routerEvent?.['urlAfterRedirects']) {
          viewportScroller.scrollToPosition(position);
        }else{
          viewportScroller.scrollToPosition([0,0]);
        }

        return NavigationActions.navigationScroll()
      })
    );
  },
  { functional: true }
);