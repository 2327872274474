import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authInitCanMatch } from '@fagor-marketplace/hmi-elite-web/data-access-auth';

const routes: Routes = [
  {
    path: '',
    canMatch: [authInitCanMatch],
    loadChildren: () => import('@fagor-marketplace/hmi-elite-web/feature'),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      // onSameUrlNavigation: 'reload',
      // scrollPositionRestoration: 'top',
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
